.navbar {
  z-index: 1;
}

.navbar-brand img {
  width: 6rem;
  height: 3rem;
}

.dropdown-item {
  font-size: 1.25rem;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

a {
  color: #0366d6;
}

.btn-primary, .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.nav-item .nav-link:hover {
  font-weight: 700;
  color: #4682b4 !important;
}

html {
  background-color: #f0f8ff;
  font-size: 14px;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem #0000000d;
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: 60px;
}

.footer {
  white-space: nowrap;
  width: 100%;
  line-height: 60px;
  position: absolute;
  bottom: 0;
}

.carousel-caption {
  z-index: 0;
  color: khaki;
  top: 20%;
  bottom: auto;
}

.carousel-item img {
  width: 100%;
  height: auto;
  display: block;
}

.about-us img {
  width: 100%;
}

.about-us .centered {
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-us .centered h1 {
  background-color: #2c82c9cc;
}

.about-us .centered p {
  font-size: 1.1rem;
}

.about-us .more-details p {
  margin-top: 10px;
}

.more-details .brush {
  width: 6.5rem;
  display: inline;
  left: 100%;
  transform: rotate(45deg);
}

.more-details .brush-container {
  position: absolute;
  top: -18px;
}

.more-details h1.has-brush {
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(45deg, red, #ff0);
  width: 100%;
  height: 4rem;
}

.more-details h1.has-brush span {
  line-height: 4rem;
}

.more-details h1 span {
  opacity: 0;
}

.more-details h1.has-brush {
  -webkit-animation: 1.5s forwards title-animation;
}

.more-details h1 span {
  -webkit-animation: 0s 1s forwards title-text-animation;
}

.more-details .brush-container {
  -webkit-animation: 1.5s forwards brush-run-animation;
}

.more-details .brush {
  -webkit-animation: 0s 1.5s forwards brush-hide-animation;
}

@-webkit-keyframes title-animation {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@-webkit-keyframes title-text-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes brush-run-animation {
  from {
    left: 0;
  }

  to {
    left: 100%;
  }
}

@-webkit-keyframes brush-hide-animation {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}

.fa-gtk-color-1 {
  color: #5f9ea0;
}

.display-4 {
  font-size: 2rem;
}

.nav-link, .dropdown-item {
  text-transform: uppercase;
  font-size: 1rem;
}

header, .main-content {
  background-color: #f0f8ff;
}

footer .container {
  color: #f0f8ff;
  background-color: #000;
  max-width: 100%;
}

button:focus {
  outline: none !important;
}

.dropdown-menu {
  background-color: #f0f8ff;
}

img.zoom {
  object-fit: cover;
  -o-transition: all .3s ease-in-out;
  border-radius: 5px;
  width: 100%;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
}

img.zoom:hover {
  box-shadow: 0 0 5px 2px red;
}

@media (width <= 767px) {
  .display-4 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .divider {
    background-color: #d3d3d3;
    height: 1px;
    margin: 0 0 3px;
    overflow: hidden;
  }
}

@media (width >= 768px) {
  html {
    font-size: 16px;
  }

  .carousel-caption {
    top: 30%;
  }

  .navbar-nav {
    padding-right: 3vw;
  }

  .nav-item {
    width: 10rem;
    height: 4rem;
    padding: 0 1vw;
    line-height: 3rem;
  }

  .dropdown-item {
    height: 2rem;
    line-height: 1.5rem;
  }

  .dropdown-menu {
    top: calc(100% - 2px);
  }

  .dropdown:hover .dropdown-menu {
    margin-top: 0;
    display: block;
  }

  .dropdown-menu .dropdown-item:hover {
    color: #4682b4 !important;
    background: none !important;
  }

  .dropdown > .dropdown-toggle:active {
    pointer-events: none;
  }
}

@media (width >= 768px) and (width <= 1000px) {
  ul.h4 {
    font-size: 2.1vw;
  }
}

@media (width <= 500px) {
  .about-us .centered {
    top: 15%;
  }
}

/*# sourceMappingURL=index.cf5d503f.css.map */
